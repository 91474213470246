import { render, staticRenderFns } from "./vacationAllowance.vue?vue&type=template&id=2fdf2862&scoped=true"
import script from "./vacationAllowance.vue?vue&type=script&lang=js"
export * from "./vacationAllowance.vue?vue&type=script&lang=js"
import style0 from "./vacationAllowance.vue?vue&type=style&index=0&id=2fdf2862&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fdf2862",
  null
  
)

export default component.exports